@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: var(--font-inter);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-gelasio);
}

#card-tag {
  font-family: var(--font-gelasio);
}

#footer-logo {
  font-family: var(--font-gelasio);
}

#content-body > table {
  width: 100%;
  white-space: nowrap; /* Prevent line breaks within table cells */
  overflow-x: auto; /* Enable horizontal scrollbar when needed */
  display: block; /* Make the table behave like a block element */
}

.kg-image-card img,
.kg-card-hascaption {
  margin: auto;
  text-align: center;
}

/* Ghost Bookmark CSS */

.kg-bookmark-card,
.kg-bookmark-card * {
  box-sizing: border-box;
}

.kg-bookmark-card,
.kg-bookmark-publisher {
  position: relative;
  width: 100%;
}

.kg-bookmark-card a.kg-bookmark-container,
.kg-bookmark-card a.kg-bookmark-container:hover {
  display: flex;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid rgb(124 139 154 / 25%);
  overflow: hidden;
  color: inherit;
}

.kg-bookmark-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  overflow: hidden;
}

.kg-bookmark-title {
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 600;
}

.kg-bookmark-description {
  display: -webkit-box;
  font-size: 1rem;
  line-height: 1.5em;
  margin-top: 3px;
  font-weight: 400;
  max-height: 44px;
  overflow-y: hidden;
  opacity: 0.7;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kg-bookmark-metadata {
  display: flex;
  align-items: center;
  margin-top: 22px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
}

.kg-bookmark-metadata > *:not(img) {
  opacity: 0.7;
}

.kg-bookmark-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.kg-bookmark-author,
.kg-bookmark-publisher {
  display: inline;
}

.kg-bookmark-publisher {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px;
  white-space: nowrap;
  display: block;
  line-height: 1.65em;
}

.kg-bookmark-metadata > span:nth-of-type(2) {
  font-weight: 400;
}

.kg-bookmark-metadata > span:nth-of-type(2):before {
  content: '•';
  margin: 0 6px;
}

.kg-bookmark-metadata > span:last-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
}

.kg-bookmark-thumbnail {
  position: relative;
  flex-grow: 1;
  min-width: 33%;
}

.kg-bookmark-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 2px 2px 0;
}

/* Ghost Image Gallery */
.kg-gallery-card,
.kg-gallery-card * {
  box-sizing: border-box;
}

.kg-gallery-card,
.kg-image-card {
  --gap: 1.2rem;
}

.kg-image-card:not(.kg-card-hascaption) + .kg-image-card,
.kg-image-card:not(.kg-card-hascaption) + .kg-gallery-card,
.kg-gallery-card:not(.kg-card-hascaption) + .kg-image-card,
.kg-gallery-card:not(.kg-card-hascaption) + .kg-gallery-card {
  margin-top: var(--gap);
}

.kg-gallery-container {
  position: relative;
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
  margin: var(--gap) 0 0;
}

.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 var(--gap);
}

@media (max-width: 600px) {
  .kg-gallery-card,
  .kg-image-card {
    --gap: 0.6rem;
  }
}
